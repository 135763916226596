export const mobile = () => {
	const toMatch = [
		/Android/i,
		/webOS/i,
		/iPhone/i,
		/iPad/i,
		/iPod/i,
		/BlackBerry/i,
		/Windows Phone/i
	];

	return toMatch.some((toMatchItem) => {
		return navigator.userAgent.match(toMatchItem);
	});
}
export const fetchCss = (lang, region) => {
	const head = document.head;
	const link = document.createElement('link');

	link.type = 'text/css';
	link.rel = 'stylesheet';

	link.href = `${window.location.origin}/api/css?lang=${lang}&region=${region}`;

	head.appendChild(link);
};

/**
 * Removing trailing param on url
 *
 */
export const removeParamUrl = () => {
	const location = window.location;
	window.history.pushState({}, document.title, location.origin + location.pathname);
};

/**
 * Get spesific param url
 *
 * @param {string} pa - Unique key of search string on url.
 */
export const getUrlParam = (pa) => {
	let url = window.location.href.replace(/#+.*$/),
		params = url.substring(url.indexOf("?") + 1, url.length).split("&"),
		param = {};

	for (let i = 0; i < params.length; i++) {
		let pos = params[i].indexOf("="),
			key = params[i].substring(0, pos),
			val = params[i].substring(pos + 1);

		param[key] = val;
	}

	return typeof param[pa] === "undefined" ? false : param[pa];
};

/**
 *
 * @param {object} err - Error response object from axios.
 */
export const err_msg = (err) => {
	if (err.response) {
		switch (err.response.status) {
			case 500:
				return "Please Refresh Your Browser!";
			default:
				return err.response.data.msg;
		}
	} else if (err.request) {
		return "Please Refresh Your Browser!";
	} else {
		return "Please Refresh Your Browser!";
	}
};

/**
 *
 * @param {string} key - Unique key for storing and identifying your data in local storage.
 * @param {*} values - Value of data you want store in local storage.
 */
export const setLsObject = (key, values) => {
	window.localStorage.setItem(key, JSON.stringify(values));
};

/**
 *
 * @param {string} key - Unique key for accessing your stored data in local storage.
 * @returns {*} - Return any type of data you stored.
 */
export const getLsObject = (key) => {
	return JSON.parse(window.localStorage.getItem(key));
};

/**
 *
 * @param {object,array} data - list data that you want to preload
 * @param {string} prefix - prefix of image key from directus (e.g. img for img_bg)
 */
export const preload = (data, prefix = false) => {
	// prettier-ignore
	const allowedExtensions = ["jpg", "jpeg", "png", "mp3", "mp4", "webm", "mpg", "mpeg", "mov", "ico", "gif", "svg", "bmp", "otf", "ttf"];
	const audioExtensions = ["mp3", "mp4", "wav"];
	let mapping = prefix ? Object.fromEntries(Object.entries(data).filter(([key]) => key.includes(prefix))) : data;
	let audios = [];
	let images = [];

	for (let i in mapping) {
		if (mapping.hasOwnProperty(i)) {
			let fileExtension = mapping[i].split(".").pop();
			if (allowedExtensions.includes(fileExtension)) {
				if (audioExtensions.includes(fileExtension)) {
					audios[i] = new Audio();
					audios[i].src = mapping[i];
				} else {
					images[i] = new Image();
					images[i].src = mapping[i];
				}
			}

		}
	}
};

/**
 *
 * @param {component} lazyComponent - React component that you want to code splitting
 * @param {number} attemptsLeft - How many attemps to load the chunks if failed
 */
export const componentLoader = (lazyComponent, attemptsLeft = 3) => {
	return new Promise((resolve, reject) => {
		lazyComponent()
			.then(resolve)
			.catch((error) => {
				// let us retry after 1500 ms
				setTimeout(() => {
					if (attemptsLeft === 1) {
						reject(error);
						return;
					}
					componentLoader(lazyComponent, attemptsLeft - 1).then(resolve, reject);
				}, 1500);
			});
	});
};
