import React from "react";
import Modal from "react-responsive-modal";
import { useDispatch, useSelector } from "react-redux";
import { put_data } from "redux/actions/main";

import 'react-responsive-modal/styles.css';

function PosterModal() {
    const dispatch = useDispatch();
    const { popup_open, popup_poster } = useSelector((state) => state.main);
    return (
        <Modal
            open={popup_open}
            focusTrapped={false}
            onClose={() => dispatch(put_data("popup_open", false))}
            closeIconId={"modal-close-icon"}
        >
            <div className="poster-modal">
                <img src={popup_poster} alt=""/>
            </div>
        </Modal>
    );
}

export { PosterModal };
