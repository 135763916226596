import React from 'react';
import { useSelector } from 'react-redux';

function Footer() {
    const links = useSelector(state => state.main.links);
     const txt = useSelector((state) => state.main.text);
    return (
        <div className='footer'>
            <div className='footer__wrapper'>
                <div className='content__left'>
                    <div className='ff-logo'></div>
                    <p className='copyright'>
                        Copyright © Garena International. <br /> Trademarks belong to their respective owners. All rights Reserved
                    </p>
                </div>
                <div className='content__right'>
                    <div className='medsos__list'>
                        <a href={links?.whatsapp} target="_blank" rel='noreferrer' className='medsos'>
                            <div className='ico whatsapp' /> <span>{txt?.text_whatsapp}</span>
                        </a>
                        <a href={links?.instagram} target="_blank" rel='noreferrer' className='medsos'>
                            <div className='ico instagram' /> <span>{txt?.text_instagram}</span>
                        </a>
                        <a href={links?.discord} target="_blank" rel='noreferrer' className='medsos'>
                            <div className='ico discord' /> <span>{txt?.text_discord}</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export { Footer };
