import React from "react"
import { NavLink } from "react-router-dom"

function Header() {
	return (
		<div className="header">
			<div className="header__wrapper">
				<div className="campgrounds-logo"></div>
				<div className="menu">
					<NavLink to={"/"}>Home</NavLink>
					<NavLink to={"/daftar"}>Daftarkan</NavLink>
					<NavLink to={"/informasi"}>Informasi</NavLink>
					<NavLink to={"/events"}>List Event</NavLink>
					<NavLink to={"/community"}>Community</NavLink>
					<NavLink to={"/leaderboard"}>leaderboard</NavLink>
				</div>
			</div>
		</div>
	)
}

export { Header }
