import axios from "axios"
import { err_msg, getLsObject } from "helper"

const cdn = axios.create({
	baseURL: `https://cdngarenanow-a.akamaihd.net/devid/${process.env.REACT_APP_LOCALIZE_NAME}/`,
	withCredentials: false,
})
const contact = axios.create({
	baseURL: `https://cdngarenanow-a.akamaihd.net/devid/glb_ff_community_contact/`,
	withCredentials: false,
})
const leaderboard = axios.create({
	baseURL: `https://cdngarenanow-a.akamaihd.net/devid/glb_ff_community_leaderboard/`,
	withCredentials: false,
})

const common = axios.create({
	baseURL: `https://cdngarenanow-a.akamaihd.net/webid/TranslateCommon/`,
	withCredentials: false,
})

export const toggle_popup = (bool, tipe) => ({
	type: "TOGGLE_POPUP",
	bool,
	tipe,
})

export const toggle_loader = (data) => ({
	type: "TOGGLE_LOADER",
	data: data,
})

export const put_data = (key, data) => ({
	type: "PUT_DATA",
	payload: {
		key: key,
		data: data,
	},
})

/**
 *
 * Example of how to call API
 *
 */
export const login = () => {
	return (dispatch, getState) => {
		// UNCOMMENT & ADD DEFAULT LANG AS PARAM ex: 'EN'
		// dispatch(get_translate('EN'))
		dispatch(toggle_loader(true))

		axios
			.get(process.env.REACT_APP_API_ENDPOINT)
			.then((resp) => {
				dispatch(put_data("login", resp.data))
			})
			.catch((err) => {
				dispatch(catch_error(err_msg(err)))
			})
			.then(() => {
				dispatch(toggle_loader(false))
			})
	}
}

export const get_events = (page, limit) => {
	return (dispatch) => {
		dispatch(toggle_loader(true))
		axios
			.get(`/api/events/?page=${page}&limit=${limit}`)
			.then((resp) => {
				dispatch(put_data("max_page", resp.data.n_pages))
				dispatch(put_data("events", resp.data.events))
			})
			.catch((err) => {})
	}
}

/**
 *
 * Example of how to implement multi language
 *
 */
export const get_translate = (default_lang) => {
	return (dispatch) => {
		let language = getLsObject("param")?.lang.toUpperCase() || default_lang

		const main_localize = cdn.get(`${language}.json?${Math.random() * 10000}`)
		const common_localize = common.get(`${language}.json?${Math.floor(Math.random() * 10000)}`)

		axios
			.all([main_localize, common_localize])
			.then((resp) => {
				const json = {
					...resp[0].data.data,
					...resp[1].data,
				}
				dispatch(put_data("lang_json", json))
			})
			.catch((err) => {})
	}
}

export const getInfo = () => {
	return (dispatch) => {
		const main_localize = cdn.get(`data.json?${Math.random() * 10000}`)
		const contact_list = contact.get(`data.json?${Math.random() * 10000}`)
		const leaderboard_list = leaderboard.get(`data.json?${Math.random() * 10000}`)

		axios.all([main_localize, contact_list, leaderboard_list]).then((resp) => {
			dispatch({
				type: "INITIAL_DATA",
				payload: {
					data: resp[0].data.data,
					contact: resp[1].data,
					leaderboard: resp[2].data,
				},
			})
		})
	}
}
/**
 *
 * Example of how to catch error if not logged in
 *
 */
export const catch_error = (err_msg) => {
	return (dispatch, getState) => {
		if (err_msg) {
			dispatch(put_data("err_msg", err_msg))
		}
		dispatch(toggle_popup(true, "error"))
		dispatch(toggle_loader(false))

		if (err_msg === "invalid_region") {
			localStorage.clear()
		}
	}
}
