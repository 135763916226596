import React, { lazy, Suspense, useEffect } from "react"
import { Routes, BrowserRouter, Route } from "react-router-dom"
import { ErrorBoundary } from "@sentry/react"
import { useDispatch, useSelector } from "react-redux"
// Component

import ViewportRestrict from "helper/component/viewport"
import Loader from "helper/component/loader"
import NotFound from "helper/component/404"
import LoadingScreen from "helper/component/loadingscreen"
import Fallback from "helper/component/fallback"
import { componentLoader } from "helper"
import { Footer, Header } from "components"
import { getInfo } from "redux/actions/main"
import { PosterModal } from "components/PosterModal"

// Pages
const Home = lazy(() => componentLoader(() => import("pages/Home")))
const Register = lazy(() => componentLoader(() => import("pages/Register")))
const Information = lazy(() => componentLoader(() => import("pages/Information")))
const ListEvent = lazy(() => componentLoader(() => import("pages/ListEvent")))
const Community = lazy(() => componentLoader(() => import("pages/Community")))
const Leaderboard = lazy(() => componentLoader(() => import("pages/Leaderboard")))

export const Main = () => {
	const { loader } = useSelector((state) => state?.main)
	const dispatch = useDispatch()
	useEffect(() => {
		dispatch(getInfo())
	}, [dispatch])

	return (
		<BrowserRouter basename={process.env.REACT_APP_BASENAME}>
			<div id="Main" className="main-panel">
				<ErrorBoundary fallback={Fallback}>
					<Suspense fallback={<LoadingScreen />}>
						<ViewportRestrict display={false} type="landscape" />
						<Loader loader={loader} />
						<PosterModal />
						<Header />
						<div className="content-container">
							<Routes>
								<Route path="/" element={<Home />} />
								<Route path="/daftar" element={<Register />} />
								<Route path="/informasi" element={<Information />} />
								<Route path="/events" element={<ListEvent />} />
								<Route path="/community" element={<Community />} />
								<Route path="/leaderboard" element={<Leaderboard />} />
								<Route path="*" element={<NotFound />} />
							</Routes>
						</div>
						<Footer />
					</Suspense>
				</ErrorBoundary>
			</div>
		</BrowserRouter>
	)
}

export default Main
