import React from 'react'

function SupportList() {
    return (
        <div className='support__list'>
            <div className='ingame support__card'></div>
            <div className='exposure support__card'></div>
            <div className='merchandise support__card'></div>
            <div className='network support__card'></div>
        </div>
    )
}

export { SupportList }