import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";

import storage from "redux-persist/lib/storage";

import main from "./main";


const rootPersistConfig = {
	key: "root",
	storage,
	whitelist: [""]
};

// const mainPersistConfig = {
// 	key: "main",
// 	storage: storage,
// 	// Ex: whitelist: ["login", "history"]
// 	// Only login and history will be persisted
// 	whitelist: [""]
// };

const rootReducer = combineReducers({
	main
});

export default persistReducer(rootPersistConfig, rootReducer);
