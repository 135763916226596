const initialState = {
	links: {},
	qna: [],
	rules_support: "rules",
	max_page: 0,
	popup_open: false,
	popup_poster: null,
}

const main = (state = initialState, { type, payload }) => {
	switch (type) {
		case "PUT_DATA":
			return { ...state, [payload.key]: payload.data }
		case "INITIAL_DATA":
			return {
				...state,
				contact: payload.contact,
				leaderboard: payload.leaderboard,
				text: payload.data,
				qna: payload.data.question_and_answer,
				links: {
					discord: payload.data.link_discord,
					form: payload.data.link_form,
					sheet: payload.data.link_sheet,
					whatsapp: payload.data.link_whatsapp,
					instagram: payload.data.link_instagram,
					sheet2: payload.data.link_sheet_informasi_dukungan,
					sheet_mobile: payload.data.link_sheet_mobile,
					sheet2_mobile: payload.data.link_sheet_informasi_dukungan_mobile,
				},
			}
		default:
			return { ...state }
	}
}
export default main
